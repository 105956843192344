import axios from "axios";
// import { Toast } from "vant";
axios.defaults.withCredentials = true;

axios.defaults.crossDomain = true;

// window.sessionStorage.setItem('TOKEN', 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiI4NTM1NmEyZmNmN2M0Y2RmYjM2ZTIwMGQ2YTlhNDBjMCIsInVzZXIiOiJ4cmV2aWV3NSIsInN1YiI6InhyZXZpZXc1In0.EAzwe8-NuNRPtSNjKqFK-u_7PtaQ9viFWK-fn6q6lvWzW4dU4eEqq1Hi19mTjN5b4l4oY7kLtXR1MKSxVoNdQA')
// 处理loading
// const toast = () =>
//     Toast.loading({
//         duration: 0, // 持续展示 toast
//         forbidClick: true,
//         loadingType: "spinner",
//         className: "loading-icon",
//         message: "加载中..."
//     });
axios.defaults.headers.common = {
  // 注意，Accept 必须加单引号，否则报错：Unquoted property 'Accept' found.
  'Accept': 'application/json, text/plain, */*',
  // Authorization: AUTH_TOKEN,
  'X-Requested-With': 'XMLHttpRequest'
};
axios.defaults.headers.post = {
  'Content-Type': 'application/json; charset=utf-8'
};
const baseURL = 'http://api.uat.oneesg.cn/';
// const baseURL = 'https://api.oneesg.cn/';
const instance = axios.create({
  baseURL: baseURL,
  // baseURL: "/api",
  timeout: 0
});
// 创建请求拦截
instance.interceptors.request.use(
  config => {
    // config.headers.Authorization = JSON.parse(window.sessionStorage.getItem('TOKEN')) || '';
    // toast();
    // config.headers['Content-Type'] = 'application/json'
    // config.data = {unused: 0}
    return config;
  },
  error => {
    console.log(333333)
    // Toast.clear();
    Promise.reject(error);
  }
);

// 创建响应拦截
instance.interceptors.response.use(
  response => {
    // Toast.clear();
    const { data } = response;

    return data;
  },
  error => {
    // Toast.clear();
    console.log(6666666, error)
    if (error?.response?.status === 401) {
      // Toast({
      //     message: '登录失效，请重新登录',
      //     duration: '2000'
      // })
      window.sessionStorage.clear()
    }

    let { message } = error;
    if (message.includes("timeout")) {
      // Toast({
      //     message: '网络开小差，请重新刷新',
      //     duration: 2000,
      // });
    }
    // if (error.response.data.data.respCode == "AE401") {
    //     Toast({
    //         message: '已在别处登录，请重新登录',
    //         duration: 3000,
    //     });
    //     setTimeout(() => {
    //         let code = window.localStorage.getItem("venueCode")
    //         router.push("/login?venueCode=" + code)
    //     }, 3000)

    // } else if (error.response.data.data.respCode == "AE402") {
    //     let code = window.localStorage.getItem("venueCode")
    //     router.push("/login?venueCode=" + code)
    // }
  }
);

export default instance;